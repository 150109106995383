import Layout from "../components/layout"
import SearchModule from "../components/search"
import React from 'react'
import bootstrap from "../components/bootstrap.module.css"
import SEO from "../components/seo"

const SearchTemplate = ({ location }) => { 
  
  const urlParams = new URLSearchParams(location.search);
  const s = urlParams.get('s')
  
  return (
    <Layout>
      <SEO title="Szukaj w fintek.pl" />  

      <h1 className={bootstrap.pb2+` `+bootstrap.mb3}>Szukaj w fintek.pl</h1> 

      <SearchModule s={s}></SearchModule>
    </Layout>
  )
}

export default SearchTemplate